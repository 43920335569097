@use '../../styles/mixins' as mixins;

.media-block {

	@include mixins.sectionStyles;

	.content-col {
		max-width: 600px;
	}

	.media-col {
		text-align: center;

		img {
			width: 100%;
			height: auto;
			aspect-ratio: 16 / 9;
			object-fit: cover;
			object-position: center;

			&.half-width {
				max-width: 400px;
				margin: 0 auto;
			}
		}

		.podcast-wrapper iframe {
			width: 100%;
		}

		.video-wrapper {

            width: 100%;

            iframe {
                aspect-ratio: 16 / 9;
                height: auto;
            }

        }

	}

	&.video-embed {

		.media-col {

			display: flex;
			align-items: center;
	
			.video-wrapper {
	
				width: 100%;
	
				iframe {
					aspect-ratio: 16 / 9;
					height: auto;
				}
	
			}
			
		}

	}

}